<template>
  <div>
    <div class="container home" v-if="true">
      <imgGenForm />
    </div>
    <br />
    <br />
    <br />
    <br />
  </div>
</template>

<script>
import { MethodColors } from "@/common/utils.js";
import systemEndpoints from "@/system.spec.json";

import imgGenForm from "@/components/studio/imgGenForm.vue";

const TABS = [
  {
    path: "",
    icon: "far fa-play",
    title: "Create",
  },
  {
    path: "/templates",
    icon: "far fa-drafting-compass",
    title: "Templates",
  },
  {
    path: "/use",
    icon: "far fa-history",
    title: "Recent",
  },

  {
    path: "/use",
    icon: "far fa-lightbulb",
    title: "Ideas",
  },
  //uploaded
  {
    path: "/use",
    icon: "far fa-image",
    title: "Uploaded",
  },

  /*
    
  {
    path: "/use",
    icon: "far fa-lightbulb",
    title: "Ideas",
  },


  {
    path: "/use",
    icon: "far fa-pencil",
    title: "Drafts",
  },

  {
    path: "/insights",
    icon: "far fa-sparkles",
    title: "Generated by users",
    isSystem: false,
  },
  {
    path: "/config",
    icon: "far fa-image",
    title: "Inspiration sources",
    isSystem: false,
  },
  {
    path: "/config",
    icon: "far fa-calendar",
    title: "Posting schedule",
    isSystem: false,
  },

  {
    path: "",
    icon: "far fa-play",
    title: "Generate",
  },
  {
    path: "/use",
    icon: "far fa-lightbulb",
    title: "Prompt generator",
  },
  {
    path: "/use",
    icon: "far fa-pencil",
    title: "Drafts",
  },

  {
    path: "/insights",
    icon: "far fa-sparkles",
    title: "Generated by users",
    isSystem: false,
  },
  {
    path: "/config",
    icon: "far fa-image",
    title: "Inspiration sources",
    isSystem: false,
  },
  {
    path: "/config",
    icon: "far fa-calendar",
    title: "Posting schedule",
    isSystem: false,
  },*/
];
export default {
  name: "Home",
  metaInfo() {
    return {
      title: this.title,
    };
  },
  components: {
    imgGenForm,
  },
  data() {
    return {
      systemEndpoints,
      MethodColors,
      generator: {},
      loadingBot: true,
      // loadingGenerators: true,
      copied: null,
    };
  },
  computed: {
    title() {
      return this.$route.params.generator;
    },
    tabs() {
      if (this.generator.isSystem) {
        // If the generator is system, return all tabs
        return TABS;
      } else {
        // If not, return only the tabs that are not system-specific
        return TABS.filter((tab) => !tab.isSystem);
      }

      return TABS;
    },
  },
  methods: {
    loadAll(opt) {
      /*
      window.API.getBotGenerators(this.$route.params.bot, opt).then((generators) => {
        var generatorId = this.$route.params.generator;
        this.generators = generators;
        this.loadingGenerators = false;
        this.generator = generators.filter((i) => i.generatorId == generatorId)[0];

        if (!this.generator) {
          this.generator = systemEndpoints.filter((i) => i.generatorId == generatorId)[0];
          if (this.generator) {
            this.generator.isSystem = true;
          }
        }
      });*/
    },
    specChange() {
      console.log("REFRESH GENERATOR data.");
      this.loadAll({ noCache: 1 });
    },
    copyGenerator(txt) {
      navigator.clipboard.writeText(txt);
      this.copied = txt;

      setTimeout(
        function () {
          this.copied = null;
          console.log("reseted clipboard!");
        }.bind(this),
        6000
      );
    },
  },
  mounted() {
    this.loadAll({ noCache: 1 });
  },
};
</script>

<style scoped>
.tabs {
  position: sticky;
  top: 0;
  z-index: 3;
  background: var(--bg-color-alpha);
  backdrop-filter: blur(5px);
}
</style>
